
:root {
  --font-family : "Verdana", "Georgia", sans-serif;
}

body {
  background-color : #DBDBDB;  
}

body * {
  font : var(--font-family)
}

.center-text {
  text-align: center;
}

.center-contents-vertical {
  align-items: center;
}

.center-contents-horizontal {
  justify-content: center;
}

.margin-top {
  margin-top : 2rem;
}

.margin-bottom {
  margin-bottom : 2rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

h1 {
  font-weight: 900;
  font-size : 32px;
}

p {
  font-size : 18px;
}

a {
  text-decoration: none;
  color : #0d6efd;
  font-weight: 900;
}